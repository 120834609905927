import 'regenerator-runtime/runtime'

class ConvStatus {

  report(statusCode) {
    const defaultData = {
      code: null,
      text: null,
      class: null,
      icon: null
    }

    if(!statusCode) {
      return defaultData
    }

    return {
      Recording: {
        code: 'Recording',
        text: 'รอข้อมูล',
        class: 'badge badge-pill badge-info',
        icon: 'fas fa-hourglass-half'
      },
      PendingReview: {
        code: 'PendingReview',
        text: 'รอตรวจสอบ',
        class: 'badge badge-pill badge-primary',
        icon: 'fas fa-user-edit'
      },
      Confirmed: {
        code: 'Confirmed',
        text: 'ตรวจแล้ว',
        class: 'badge badge-pill badge-success',
        icon: 'fas fa-check'
      }
    }[statusCode] || defaultData
  }

}

export default new ConvStatus()
