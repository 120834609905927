<template>
  <div id="daily-report" class="container">

    <h3>สรุปยอดรายวัน</h3>
    <CardDateFrom @selected="searchData" />

    <div v-if="items.length > 0">
      <span class="text-primary">ข้อมูลวันที่ {{dataDateText}}</span>
      <div class="card mb-3 py-3">
        <bar-chart :chartdata="chartCollection" :options="chartOptions"></bar-chart>
        <div class="text-center mt-4">
          <b-form-checkbox-group
            v-model="chartSelected"
            :options="chartSelectOptions"
            name="chart-select"
          ></b-form-checkbox-group>
        </div>
      </div>
    </div>

    <span class="text-primary">ข้อมูลวันที่ {{dataDateText}}</span>
    <div class="card">
      <table class="table table-sm table-hover table-bordered mb-0">
        <thead>
          <tr>
            <th rowspan="2" class="text-center" style="width: 15%; vertical-align: middle;">วันที่</th>
            <th rowspan="2" class="text-right" style="width: 10%; vertical-align: middle;">เงินตั้งต้น</th>
            <th colspan="4" class="text-center">ยอดเว็บ</th>
            <th rowspan="2" class="text-right" style="width: 10%; vertical-align: middle;">โอนกลับบัญชีกลาง</th>
            <th rowspan="2" class="text-right" style="width: 10%; vertical-align: middle;">เหลือ</th>
            <th rowspan="2" class="text-center" style="vertical-align: middle;">สถานะ</th>
          </tr>
          <tr>
            <td style="width: 10%;" class="text-right">ยอดคีย์</td>
            <td style="width: 10%;" class="text-right">ยอดจ่าย</td>
            <td style="width: 10%;" class="text-right">ขาด/เกิน</td>
            <td style="width: 10%;" class="text-right">รวม</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items">
            <td class="text-center"><router-link :to="{name: 'BranchDateRecord', params: {date: dateFormat(item.date)}}">{{ item.date | dateFormat }}</router-link></td>
            <td class="text-right"><span v-html="numberFormat(item.summary.start.amount)" class="font-weight-bold"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.sales)"></span> <!-- <i class="fas view-info fa-info-circle text-primary"></i> --></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.won)"></span> <!-- <i class="fas view-info fa-info-circle text-primary"></i> --></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.other)"></span> <!-- <i class="fas view-info fa-info-circle text-primary"></i> --></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.total)" class="font-weight-bold"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.summary.refund.amount)" class="font-weight-bold"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.summary.grandTotal)" class="font-weight-bold"></span></td>
            <td class="text-center" v-html="reportStatus(item.status)"></td>
          </tr>
          <tr v-if="!items.length">
            <td colspan="9" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="items.length > 0">
          <tr style="font-size: 120%">
            <th class="text-center">รวม</th>
            <th></th>
            <th class="text-right"><span v-html="numberFormat(total.sales)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.won)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.other)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.total)"></span></th>
            <th colspan="3"></th>
          </tr>
        </tfoot>
      </table>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import Swal from 'sweetalert2'

import moment from '@/helpers/moment'
import ConvStatus from '@/helpers/conv-status'

import ReportService from '@/services/ReportService'

import BarChart from '@/views/components/BarChart'
import CardDateFrom from '@/views/components/CardDateFrom'

export default {
  name: 'BranchReportSummary',
  components: {
    Loading,
    BarChart,
    CardDateFrom
  },
  data() {
    return {
      isLoading: false,
      dataDate: null,
      successDataDate: null,
      data: [],

      chartSelected: ['sales', 'wons'],
      chartSelectOptions: [
        { text: 'ยอดคีย์', value: 'sales' },
        { text: 'ยอดจ่าย', value: 'wons' },
        { text: 'ขาด/เกิน', value: 'others' },
        { text: 'รวม', value: 'totals' }
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }

    }
  },
  computed: {
    dataDateText() {
      if(!this.successDataDate) {
        return '-'
      }

      const diff = moment(this.dataDate.end).diff(this.dataDate.start, 'd') + 1

      if(this.successDataDate.start === this.successDataDate.end) {
        return `${this.successDataDate.start} รวม ${diff} วัน`
      }else{
        return `${this.successDataDate.start} ถึง ${this.successDataDate.end} ข้อมูลทั้งหมด ${this.items.length} วัน`
      }
    },
    items() {
      return this.data
    },
    total() {
      return this.data.reduce((total, d)=>{
        total.sales += d.amounts.sales
        total.won += d.amounts.won
        total.other += d.amounts.other
        total.total += d.amounts.total
        total.realBalance += d.amounts.realBalance
        return total
      }, {
        sales: 0,
        won: 0,
        other: 0,
        total: 0,
        realBalance: 0
      })
    },
    chartCollection() {

      const labels = this.items.map((item)=>{
        return this.dateFormat(item.date)
      })

      let datasets = {}
      datasets.sales = {
        label: 'ยอดคีย์',
        backgroundColor: '#c3e6cb',
        data: this.items.map((item)=>{
          return Math.abs(item.amounts.sales || 0)
        })
      }

      datasets.wons = {
        label: 'ยอดจ่าย',
        backgroundColor: '#f5c6cb',
        data: this.items.map((item)=>{
          return Math.abs(item.amounts.won || 0)
        })
      }

      datasets.others = {
        label: 'รายรับ/รายจ่าย',
        backgroundColor: '#bee5eb',
        data: this.items.map((item)=>{
          return item.amounts.other || 0
        })
      }

      datasets.totals = {
        label: 'รวม',
        backgroundColor: '#b8daff',
        data: this.items.map((item)=>{
          return item.amounts.total || 0
        })
      }

      return {
        labels: labels,
        datasets: this.chartSelected.map((select)=>{
          return datasets[select]
        })
      }
    }
  },
  methods: {
    searchData(params) {
      this.dataDate = params?.date

      this.isLoading = true
      ReportService.getSummary(this.dataDate)
      .then((response)=>{
        if(response.status === 'success') {
          this.successDataDate = this.dataDate
          this.data = response.data
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    numberFormat(number, dec=2) {
      if(!number || number==='-')
        return '-'

      number = parseFloat(number).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(number > 0)
        return `<span class="text-success">${numberText}</span>`
      else if(number < 0)
        return `<span class="text-danger">${numberText}</span>`

      return numberText
    },
    dateFormat(arg) {
      return moment(arg).format("YYYY-MM-DD")
    },
    reportStatus(code) {
      const status = ConvStatus.report(code)
      return `<span class="${status.class}"><i class="${status.icon}"></i> ${status.text}</span>`
    }
  },
  filters: {
    dateFormat(arg) {
      return moment(arg).format("YYYY-MM-DD")
    }
  }
}
</script>
<style lang="scss" scoped>
#daily-report {
  position: relative;
  padding-bottom: 50px;

  .view-info {
    cursor: pointer;
  }

  .form-check {
    display: flex;
    align-items: center;

    input[type=radio] {
      width: 20px;
      height: 20px;
      margin-top: 0;
    }

    label {
      margin-left: 8px;
    }

    .label-input {
      width: 60px;
    }
  }
}
</style>
